import { forwardRef } from "@heroui/system-rsc";
import { Loader } from "lucide-react";
import { HTMLAttributes } from "react";
import { UseSpinnerProps, useSpinner } from "./use-spinner";

export interface SpinnerProps
  extends UseSpinnerProps,
    Omit<HTMLAttributes<HTMLDivElement>, "color"> {}

export const Spinner = forwardRef<"div", SpinnerProps>((props, ref) => {
  const { classNames, getSpinnerProps, label, slots } = useSpinner({
    ...props,
  });

  return (
    <div ref={ref} {...getSpinnerProps()}>
      <div className={slots.wrapper({ class: classNames?.wrapper })}>
        <Loader className={slots.loader({ class: classNames?.loader })} />
      </div>
      {label && (
        <span className={slots.label({ class: classNames?.label })}>
          {label}
        </span>
      )}
    </div>
  );
});

Spinner.displayName = "Recare.Spinner";
